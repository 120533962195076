import styled from "@emotion/styled";
import { CONF_EVENT_TYPE_EARNINGS_CALL, EarningsConferenceCall, EarningsDetailResponse, EarningsResponse } from "../../api";
import icDefault from "../../component/icon/ic_default.svg";
import SmallButton from "../buttons/SmallButton";
import { Colors } from "../Color";
import { Space } from "../space";
import Typography from "../Typography";

import { DateTimeUtils } from "../../util/time";
import IconPlay from "./images/play.svg";
import IconPlayHover from "./images/play_hover.svg";
import IconPlaySelected from "./images/play_selected.svg";

import IconLiveBadge from "./images/live_badge.svg";
import { css } from "@emotion/react";
import TickerIcon from "../icon/TickerIcon";

interface Props {
  earnings: EarningsResponse | EarningsConferenceCall;
  onClick?: () => void;
  selected?: boolean;
  hideControls?: boolean;
  style?: React.CSSProperties;
  hover?: boolean;
}

const Container = styled.div<{ hover?: boolean }>`
  display: flex;
  align-items: center;
  height: 46px;
  padding: 20px;
  cursor: pointer;

  ${({ hover }) =>
    hover &&
    css`
      :hover {
        background-color: ${Colors.gray100};
      }
    `}
`;

const TickerImgContainer = styled.div`
  position: relative;
`;

const LiveAudioState = styled.div`
  position: absolute;
  top: -3px;
  left: -3px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1.5px solid ${Colors.red50};

  > div {
    position: absolute;
    bottom: -6px;
    left: 9.5px;
    width: 27px;
    height: 14px;
    border-radius: 40px;
    background-color: ${Colors.red50};

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 15.6px;
      height: 5.64px;
    }
  }
`;

const TickerImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #64646466;
`;

const TickerDesc = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const Tags = styled.div`
  flex: 1;
  padding: 0 10px;

  > span {
    background-color: #cecece;
    color: #000;
    font-weight: bold;
    padding: 2px 8px;
    margin: 0 4px 0 0;
    border-radius: 10px;
    font-size: 12px;
  }
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
`;

const TimeWarpper = styled.div`
  display: flex;
  align-items: center;

  height: 33px;
  padding: 0 12px;
  border: 1px solid ${Colors.gray90};
  border-radius: 40px;
`;

export default function TickerItem({ earnings, onClick, selected, hideControls, style, hover }: Props) {
  // check earnings type
  const liveEventAt = (earnings as EarningsResponse).liveEventAt
    ? (earnings as EarningsResponse).liveEventAt
    : (earnings as EarningsConferenceCall).eventAt;

  // check type
  const isQna = (earnings as EarningsResponse).isQna ?? false;

  const transcriptUrl = (earnings as EarningsResponse).transcriptUrl
    ? (earnings as EarningsResponse).transcriptUrl
    : (earnings as EarningsConferenceCall).koLiveTranscriptUrl;

  var liveAudioState = (earnings as EarningsResponse).liveAudioState
    ? (earnings as EarningsResponse).liveAudioState
    : (earnings as EarningsConferenceCall).liveAudioState;

  const isEarningsCall = (earnings as EarningsConferenceCall).eventType !== CONF_EVENT_TYPE_EARNINGS_CALL ? false : true;
  return (
    <Container onClick={onClick} style={style} hover={hover}>
      <TickerImgContainer>
        <TickerIcon iconUrl={earnings.tickerIconUrl} name={earnings.ticker} />
        {liveAudioState === "live" && (
          <LiveAudioState>
            <div>
              <img src={IconLiveBadge} alt="live-badge" />
            </div>
          </LiveAudioState>
        )}
      </TickerImgContainer>
      <TickerDesc>
        <Typography variant="8/bold" style={{ overflow: "hidden", whiteSpace: "nowrap", maxWidth: "130px" }}>
          {earnings.ticker}
        </Typography>
        <Typography variant="6/regular" color={Colors.gray60} style={{ overflow: "hidden", whiteSpace: "nowrap", maxWidth: "200px" }}>
          {isEarningsCall ? `${earnings.periodYear} ${earnings.period} 어닝콜 ${isQna ? "Q&A" : ""}` : earnings.eventTitle}
        </Typography>
      </TickerDesc>

      <Space />
      {!hideControls && (
        <Stats>
          {transcriptUrl ? (
            <SmallButton icon={IconPlay} selectedIcon={IconPlaySelected} hoverIcon={IconPlayHover} selected={selected} circle />
          ) : (
            <TimeWarpper>
              <Typography variant="6/regular" text={DateTimeUtils.formatTimeSimple(liveEventAt!!)} color={Colors.gray40} />
            </TimeWarpper>
          )}
        </Stats>
      )}
    </Container>
  );
}
