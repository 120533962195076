import styled from "@emotion/styled";
import { EarningsResponse } from "../../api";
import { Colors } from "../../component/Color";
import { Space } from "../../component/space";
import Typography from "../../component/Typography";
import { DateTimeUtils } from "../../util/time";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 10px 0;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  border: 0.5px solid ${Colors.gray10};
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
`;

const Image = styled.img`
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TimeText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.gray80};
`;

export default function EarningsItem({ earning }: { earning: EarningsResponse }) {
  return (
    <Container>
      <ImageContainer>
        <Image src={earning.tickerIconUrl} alt={earning.companyName} />
      </ImageContainer>
      <Space width={6} />

      <TimeText>
        <Typography
          variant="6/semibold"
          color={Colors.black}
          textAlign="center"
          style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "100px" }}
        >
          {earning.localCompanyName}
        </Typography>
        <Typography variant="5/medium" color={Colors.gray60} textAlign="center">
          {DateTimeUtils.formatTimeSimpleShort(earning.eventAt)}
        </Typography>
      </TimeText>
    </Container>
  );
}
